import { DeprecatedAttachment, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useSignedUrlFileDownload } from "../../00_shared/hooks/useSignedUrlFileDownload.hook"
import { usePayTranslation } from "../../01_technical/translations"

const Container = styled.div`
  & div {
    max-width: 8rem;
  }
`

const StyledAttachmentTitle = styled(Typography)`
  margin-left: 0.3rem;
  color: ${({ theme }) => theme.colors.grey.$600};
`

export const AttachmentContainer = ({ downloadUrl }: { downloadUrl?: string }) => {
  const [fileDownload] = useSignedUrlFileDownload()

  const { t } = usePayTranslation()

  return (
    <Container>
      <StyledAttachmentTitle $variant="body-3-semibold">{t("attachments")}</StyledAttachmentTitle>
      {!downloadUrl ? (
        <Typography $variant="body-3-regular">{t("attachment.empty")}</Typography>
      ) : (
        <DeprecatedAttachment
          name={t("attachment.name")}
          canDelete={false}
          onView={() => fileDownload(downloadUrl)}
          canView={true}
        />
      )}
    </Container>
  )
}
