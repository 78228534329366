import { DeprecatedSpinner } from "@hero/krypton"
import i18next from "i18next"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { usePayTranslation } from "../../../01_technical/translations"
import { SupportedLanguage } from "../../../business/enum/SupportedLanguage"
import { LANDING_URI } from "../../../env_variables"
import { useGetPaymentCheckout } from "../requests.ts/GetPaymentCheckoutConfiguration"

const PRIVACY_POLICY = "politique-de-confidentialite"

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const PaymentCheckoutPage = () => {
  const paymentCheckoutId = useParams<{ id: string }>().id
  const navigate = useNavigate()

  const lang = i18next.language as SupportedLanguage
  const privacyPolicyUrl =
    lang === SupportedLanguage.FR ? `${LANDING_URI}/${PRIVACY_POLICY}` : `${LANDING_URI}/${lang}/${PRIVACY_POLICY}`

  const { t } = usePayTranslation()

  const { data, loading, error } = useGetPaymentCheckout({ paymentCheckoutId })

  useEffect(() => {
    if (!data) return

    if (data.isInitiated) {
      navigate(`/checkout/${paymentCheckoutId}/details`, {
        state: {
          skipPaymentTypeSelection: true,
        },
      })

      return
    }

    const paymentType = data.availablePaymentTypesWithInstallments[0]

    navigate(`/checkout/${paymentCheckoutId}/details`, {
      state: {
        paymentTypeSelected: paymentType,
        merchantTradingName: data.merchantTradingName,
        amount: data.amount,
        privacyPolicyUrl,
        skipPaymentTypeSelection: true,
      },
    })
  }, [data, paymentCheckoutId, navigate, privacyPolicyUrl])

  if (loading) {
    return (
      <CenteredContent>
        <DeprecatedSpinner />
      </CenteredContent>
    )
  }

  if (error || !data || !paymentCheckoutId) {
    return <CenteredContent>{t("notFound")}</CenteredContent>
  }

  return (
    <CenteredContent>
      <DeprecatedSpinner />
    </CenteredContent>
  )
}
