import { DeprecatedSpinner, KryptonProvider } from "@hero/krypton"
import { Suspense } from "react"

import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { appRoutes } from "./App.router"

export const App = () => {
  const browserRouter = createBrowserRouter(appRoutes)

  return (
    <KryptonProvider>
      <Suspense fallback={<DeprecatedSpinner />}>
        <RouterProvider router={browserRouter} />
      </Suspense>
    </KryptonProvider>
  )
}
