import { DeprecatedRadio } from "@hero/krypton"
import { useEffect } from "react"
import styled from "styled-components"
import { usePayTranslation } from "../../01_technical/translations"
import { PaymentType } from "../../business/enum/PaymentType"
import { AvailablePaymentType } from "../business/Installment.type"
import { InstallmentsSlider } from "./InstallmentsSlider"
import { PaymentHeader } from "./PaymentHeader"

const Container = styled.div`
  background: linear-gradient(180deg, #f8f8f8 0%, rgba(248, 248, 248, 0.1) 100%);
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  border-radius: 1rem;
  width: 90%;
  padding-top: 1rem;
  margin-bottom: 1.5rem;
  media (max-width: 800px) {
    width: 100vw;
    overflow: hidden;
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  & > p {
    margin: 0.5rem 0;
    text-align: center;
  }
`

const PaymentTypeSelector = styled.div`
  margin: 1rem 2rem;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey.$200};
`

export const PaymentTypeChoice = ({
  amount,
  availablePaymentTypes,
  paymentTypeSelected,
  setPaymentTypeSelected,
  merchantTradingName,
  logoUrl,
}: {
  amount: number
  availablePaymentTypes: AvailablePaymentType[]
  paymentTypeSelected: AvailablePaymentType | null
  setPaymentTypeSelected: (value: AvailablePaymentType) => void
  merchantTradingName: string
  logoUrl?: string
}) => {
  const { t } = usePayTranslation()

  useEffect(() => {
    if (!paymentTypeSelected) {
      setPaymentTypeSelected(availablePaymentTypes[0])
    }
  }, [paymentTypeSelected, setPaymentTypeSelected, availablePaymentTypes])

  const getPaymentTypeText = (availablePaymentType: AvailablePaymentType) => {
    switch (availablePaymentType.type) {
      case PaymentType.PAY_1X:
        return t("paymentTypeChoice.pay_1x")
      case PaymentType.PAY_NX:
        return t("paymentTypeChoice.pay_nx", { installmentCount: availablePaymentType.installmentCount })
      case PaymentType.PAY_ND:
        return t("paymentTypeChoice.pay_nd", { daysBeforeDueDate: availablePaymentType.daysBeforeDueDate })
      default:
        return t("paymentType.error")
    }
  }

  if (!paymentTypeSelected) {
    return <div>Unexpected error</div>
  }

  return (
    <Container>
      <HeaderContent>
        <PaymentHeader
          logoUrl={logoUrl}
          merchantTradingName={merchantTradingName}
          amount={amount}
          paymentTypeSelected={paymentTypeSelected}
        />
        {paymentTypeSelected.type === PaymentType.PAY_NX && (
          <InstallmentsSlider installments={paymentTypeSelected.installments} />
        )}
      </HeaderContent>
      <PaymentTypeSelector>
        {availablePaymentTypes.map((availablePayment, index) => {
          const isLastElement = availablePayment === availablePaymentTypes[availablePaymentTypes.length - 1]
          return (
            <div key={index}>
              <DeprecatedRadio
                checked={
                  paymentTypeSelected.daysBeforeDueDate === availablePayment.daysBeforeDueDate &&
                  paymentTypeSelected.installmentCount === availablePayment.installmentCount
                }
                onChange={() => setPaymentTypeSelected(availablePayment)}
                value={index.toString()}
                id={index.toString()}
                text={getPaymentTypeText(availablePayment)}
              />
              {!isLastElement && <Line />}
            </div>
          )
        })}
      </PaymentTypeSelector>
    </Container>
  )
}
