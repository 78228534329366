import { useMutation } from "@apollo/client"
import { CalendarIcon, CreditCardIcon, DeprecatedTooltip, InfoIcon } from "@hero/krypton"
import { CardNumber, Cvv, ExpiryDate, Frames } from "frames-react"
import styled from "styled-components"
import { usePayTranslation } from "../../../01_technical/translations"
import { CHECKOUT_COM_PUBLIC_KEY } from "../../../env_variables"
import { ADD_NEW_CARD, ADD_NEW_CARD_ARGS, ADD_NEW_CARD_RESPONSE } from "./requests.ts/AddNewCard.requests"
import {
  INIT_PAYMENT_CHECKOUT,
  INIT_PAYMENT_CHECKOUT_ARGS,
  INIT_PAYMENT_CHECKOUT_RESPONSE,
} from "./requests.ts/InitPaymentCheckout.requests"

const CardInputBlockContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`

const InputContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.75rem;
  width: 100%;
  height: 2.75rem;
  color: ${({ theme }) => theme.colors.grey.$400};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  > div {
    height: 2.75rem;
    display: flex;
    align-items: center;
  }
`

const CardNumberInput = styled(InputContainer)`
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  > div {
    width: 100%;
    padding-left: 0.4rem;
  }
`

const CardExpiryInputContainer = styled(InputContainer)`
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  > div {
    width: 100%;
    padding-left: 0.4rem;
  }
`

const CardCVCInputContainer = styled(InputContainer)`
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 0.5rem;
`

const CardAdditionalFields = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

const StyledTooltip = styled(DeprecatedTooltip)`
  display: flex;
  justify-content: center;
  height: inherit;
`

export const CheckoutComCardInputs = ({
  paymentId,
  feeConfigurationId,
  setIsSubmitLoading,
  setError,
  isInitiated,
}: {
  paymentId: string
  feeConfigurationId: string
  setIsSubmitLoading: (value: boolean) => void
  setError: (message: string) => void
  isInitiated: boolean
}) => {
  const { t } = usePayTranslation()

  const [initPaymentCheckout] = useMutation<INIT_PAYMENT_CHECKOUT_RESPONSE, INIT_PAYMENT_CHECKOUT_ARGS>(
    INIT_PAYMENT_CHECKOUT,
  )

  const [addNewCard] = useMutation<ADD_NEW_CARD_RESPONSE, ADD_NEW_CARD_ARGS>(ADD_NEW_CARD)

  const handleTechnicalError = () => {
    setError(t("paymentPage.card.technicalError"))
    setIsSubmitLoading(false)
    Frames.init()
  }

  return (
    <Frames
      config={{
        debug: false,
        publicKey: CHECKOUT_COM_PUBLIC_KEY,
        localization: {
          cardNumberPlaceholder: "Numéro de carte",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVV",
        },
        style: {
          placeholder: {
            base: {
              color: "#919299",
            },
          },
          base: {
            font: "Inter",
            fontSize: "14px",
            color: "#1D1E21",
          },
        },
      }}
      cardTokenized={async (e) => {
        if (isInitiated) {
          let addNewCardResult
          try {
            addNewCardResult = await addNewCard({
              variables: {
                paymentCheckoutId: paymentId,
                cardTokenId: e.token,
              },
            })
          } catch (e) {
            handleTechnicalError()
            return
          }
          window.location.href = `${addNewCardResult.data?.addNewCardToPaymentCheckout.redirectUrl}`
          setIsSubmitLoading(false)
        } else {
          let initPaymentCheckoutResult
          try {
            initPaymentCheckoutResult = await initPaymentCheckout({
              variables: {
                paymentCheckoutId: paymentId,
                paymentFeeConfigurationId: feeConfigurationId,
                card: {
                  token: e.token,
                  issuer: e.issuer,
                  issuerCountry: e.issuer_country,
                  cardType: e.card_type,
                  productType: e.product_type,
                  expiryMonth: Number(e.expiry_month),
                  expiryYear: Number(e.expiry_year),
                  cardCategory: e.card_category,
                },
              },
            })
          } catch (e) {
            handleTechnicalError()
            return
          }
          if (initPaymentCheckoutResult.data?.initiatePaymentCheckout.errorCode) {
            setError(t("paymentPage.card.notAccepted"))
            setIsSubmitLoading(false)
            Frames.init()
          }
          if (initPaymentCheckoutResult.data?.initiatePaymentCheckout.redirectUrl) {
            window.location.href = `${initPaymentCheckoutResult.data?.initiatePaymentCheckout.redirectUrl}`
            setIsSubmitLoading(false)
          }
        }
      }}
      cardTokenizationFailed={(e) => {
        setError(t("paymentPage.card.notValid"))
        setIsSubmitLoading(false)
        Frames.init()
      }}
    >
      <CardInputBlockContainer>
        <CardNumberInput>
          <CreditCardIcon />
          <CardNumber />
        </CardNumberInput>
        <CardAdditionalFields>
          <CardExpiryInputContainer>
            <CalendarIcon />
            <ExpiryDate />
          </CardExpiryInputContainer>
          <CardCVCInputContainer>
            <StyledTooltip content={t("cardInput.cvv.info")} position="bottom-left" id="tooltip-cvc">
              <InfoIcon />
            </StyledTooltip>
            <Cvv />
          </CardCVCInputContainer>
        </CardAdditionalFields>
      </CardInputBlockContainer>
    </Frames>
  )
}
