import { deprecatedToaster } from "@hero/krypton"
import * as Sentry from "@sentry/react"
import i18n, { BackendModule, TFunction, TOptions } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { useEffect } from "react"
import { UseTranslationOptions, initReactI18next, useTranslation } from "react-i18next"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { DeepTranslationKeys } from "./translationTypes.utils"

export enum AvailableLanguage {
  EN = "en",
  FR = "fr",
  ES = "es",
  DE = "de",
  IT = "it",
}

export const FallbackLanguage = AvailableLanguage.EN as const

export const EnabledLanguages = [
  AvailableLanguage.FR,
  AvailableLanguage.EN,
  AvailableLanguage.ES,
  AvailableLanguage.DE,
  AvailableLanguage.IT,
]

export enum TranslationNamespaces {
  PAY = "pay",
  COMMON = "common",
}

const translationsByNamespaceByLang = {
  [AvailableLanguage.EN]: {
    [TranslationNamespaces.PAY]: () => import("../i18n/pay/en.json"),
    [TranslationNamespaces.COMMON]: () => import("../i18n/common/en.json"),
  },
  [AvailableLanguage.FR]: {
    [TranslationNamespaces.PAY]: () => import("../i18n/pay/fr.json"),
    [TranslationNamespaces.COMMON]: () => import("../i18n/common/fr.json"),
  },
  [AvailableLanguage.ES]: {
    [TranslationNamespaces.PAY]: () => import("../i18n/pay/es.json"),
    [TranslationNamespaces.COMMON]: () => import("../i18n/common/es.json"),
  },
  [AvailableLanguage.DE]: {
    [TranslationNamespaces.PAY]: () => import("../i18n/pay/de.json"),
    [TranslationNamespaces.COMMON]: () => import("../i18n/common/de.json"),
  },
  [AvailableLanguage.IT]: {
    [TranslationNamespaces.PAY]: () => import("../i18n/pay/it.json"),
    [TranslationNamespaces.COMMON]: () => import("../i18n/common/it.json"),
  },
}

type CommonType = Awaited<
  ReturnType<(typeof translationsByNamespaceByLang)[AvailableLanguage.FR][TranslationNamespaces.COMMON]>
>

const LazyImportPlugin: BackendModule = {
  type: "backend",
  init: function () {
    return this
  },
  read: (language, namespace, callback) => {
    const lang = language as AvailableLanguage
    const ns = namespace as TranslationNamespaces
    if (translationsByNamespaceByLang[lang] && translationsByNamespaceByLang[lang][ns] !== undefined) {
      translationsByNamespaceByLang[lang][ns]().then((resource) => callback(null, resource))
    } else {
      const msg = `Tried to load a translation resource that is not configured: ${namespace} :: ${lang}. This could be due to a missing translation file or a bad call to i18n.loadNamespaces `
      console.error(msg)
      Sentry.captureMessage(msg, "error")
      callback(
        `Unable to load translation resource. No provider is configured for language ${language} in namespace ${ns}`,
        {},
      )
    }
  },
}

const fallbackLng = {
  [AvailableLanguage.EN]: [AvailableLanguage.FR],
  [AvailableLanguage.FR]: [AvailableLanguage.EN],
  [AvailableLanguage.DE]: [AvailableLanguage.EN, AvailableLanguage.FR],
  [AvailableLanguage.ES]: [AvailableLanguage.EN, AvailableLanguage.FR],
  [AvailableLanguage.IT]: [AvailableLanguage.EN, AvailableLanguage.FR],
}

export const initI18next = () => {
  i18n
    .use(initReactI18next)
    .use(LazyImportPlugin)
    .use(LanguageDetector)
    .init({
      detection: {
        order: ["path", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag"],
      },

      ns: [TranslationNamespaces.COMMON],
      supportedLngs: EnabledLanguages,
      fallbackLng,
      defaultNS: false,
      interpolation: {
        escapeValue: false,
      },
      saveMissing: true,
      returnEmptyString: false,
      returnNull: false,
    })

  i18n.on("missingKey", (lngs, namespace, key, fallbackValue) => {
    const msg = `Missing translation for language "${lngs}": ${namespace} :: ${key}. Fallback shown: "${fallbackValue}"`
    console.error(msg)
    Sentry.captureMessage(msg, "error")
  })

  i18n.on("languageChanged", () => {
    document.documentElement.lang = i18n.language
  })

  return i18n
}

export const RouterConnectedTranslation: React.FC = () => {
  const { lang } = useParams()
  const navigate = useNavigate()
  const { t, i18n } = useCommonTranslation()

  useEffect(() => {
    const langFileExtensionExcluded = lang && lang.replace(/\..*$/, "")
    if (langFileExtensionExcluded && !EnabledLanguages.includes(langFileExtensionExcluded as AvailableLanguage)) {
      deprecatedToaster.error(t("root.languageNotImplemented"))
      navigate("/")
    }
  }, [lang, navigate, t, i18n])

  return <Outlet />
}

export const usePayTranslation = (options?: UseTranslationOptions<string>) => {
  const { t, ...rest } = useTranslation(TranslationNamespaces.PAY, options)

  const customTypedT: (key: string, options?: TOptions) => ReturnType<TFunction<TranslationNamespaces, string>> = t

  return { t: customTypedT, unsafeT: t, ...rest }
}

export type DashboardTFunction = ReturnType<typeof usePayTranslation>["t"]

export const useCommonTranslation = (options?: UseTranslationOptions<string>) => {
  const { t, ...rest } = useTranslation(TranslationNamespaces.COMMON, options)

  const customTypedT: <K extends string>(
    key: DeepTranslationKeys<CommonType, K>,
    options?: TOptions,
  ) => ReturnType<typeof t> = t

  return { t: customTypedT, unsafeT: t, ...rest }
}

export type CommonTFunction = ReturnType<typeof useCommonTranslation>["t"]

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false
  }
}
