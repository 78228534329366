import { DeprecatedButton, DeprecatedSpinner } from "@hero/krypton"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { centsToEuros } from "../../../00_shared/utils/currency.converter"
import { usePayTranslation } from "../../../01_technical/translations"
import { LockLogo } from "../../../assets/logos/Lock"
import { SupportedLanguage } from "../../../business/enum/SupportedLanguage"
import { API_URI, LANDING_URI } from "../../../env_variables"
import { AvailablePaymentType } from "../../business/Installment.type"
import { AttachmentContainer } from "../../components/Attachments"
import { PaymentTypeChoice } from "../../components/PaymentTypeChoice"
import { Footer } from "../../shared/Footer/Footer"
import { Header } from "../../shared/Header/Header"
import { useGetPaymentLink } from "../requests.ts/GetPaymentLinkConfiguration"

const PRIVACY_POLICY = "politique-de-confidentialite"

const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const Backgound = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  width: 42.5rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  border-radius: 1rem;
  margin: 2rem 2rem;
  padding: 0.5rem 0;
  max-height: 44rem;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ButtonContainer = styled.div`
  width: 90%;
  margin-top: 1.5rem;
  button > div {
    display: flex;
    align-items: center;
  }
`

const AttachementContainerBox = styled.div`
  align-self: flex-start;
  margin-left: 4rem;
`

const WhiteLockLogoContainer = styled.div`
  width: 1rem;
  height: 1rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`

const WhiteLockLogo = () => {
  return (
    <WhiteLockLogoContainer>
      <LockLogo color="white" />
    </WhiteLockLogoContainer>
  )
}

export const PaymentLinkPage = () => {
  const paymentLinkId = useParams<{ id: string }>().id
  const navigate = useNavigate()

  const lang = i18next.language as SupportedLanguage
  const privacyPolicyUrl =
    lang === SupportedLanguage.FR ? `${LANDING_URI}/${PRIVACY_POLICY}` : `${LANDING_URI}/${lang}/${PRIVACY_POLICY}`

  const [hasChosenPaymentType, setHasChosenPaymentType] = useState<boolean>(false)

  const { t } = usePayTranslation()

  const { data, loading, error } = useGetPaymentLink({ paymentLinkId })

  const [paymentTypeSelected, setPaymentTypeSelected] = useState<AvailablePaymentType | null>(null)

  useEffect(() => {
    if (!data?.availablePaymentTypesWithInstallments) return
    setPaymentTypeSelected(data.availablePaymentTypesWithInstallments[0])
  }, [data?.availablePaymentTypesWithInstallments])

  useEffect(() => {
    if (!data) return

    if (data.isInitiated) {
      navigate(`/link/${paymentLinkId}/details`, {
        state: {
          skipPaymentTypeSelection: true,
        },
      })

      return
    }

    if (data.availablePaymentTypesWithInstallments.length === 1) {
      const paymentType = data.availablePaymentTypesWithInstallments[0]

      navigate(`/link/${paymentLinkId}/details`, {
        state: {
          paymentTypeSelected: paymentType,
          merchantTradingName: data.merchantTradingName,
          amount: data.amount,
          privacyPolicyUrl,
          attachmentPath: data.attachmentPath,
          skipPaymentTypeSelection: true,
        },
      })
    }
  }, [data, paymentLinkId, navigate, privacyPolicyUrl])

  if (loading) {
    return (
      <CenteredContent>
        <DeprecatedSpinner />
      </CenteredContent>
    )
  }

  if (error || !data || !paymentLinkId) {
    return <CenteredContent>{t("notFound")}</CenteredContent>
  }

  const { merchantTradingName, amount, merchantLogo, availablePaymentTypesWithInstallments, attachmentPath } = data

  const goToPaymentPage = () => {
    setHasChosenPaymentType(true)
    navigate(`/link/${paymentLinkId}/details`, {
      state: {
        paymentTypeSelected,
        merchantTradingName,
        amount,
        attachmentPath,
        privacyPolicyUrl,
        skipPaymentTypeSelection: false,
      },
    })
  }

  return (
    <FullPage>
      <Header merchantTradingName={merchantTradingName} $hasChosenPaymentType={hasChosenPaymentType} />
      <Backgound>
        <Container>
          <PaymentTypeChoice
            amount={centsToEuros(amount)}
            logoUrl={merchantLogo}
            availablePaymentTypes={availablePaymentTypesWithInstallments}
            merchantTradingName={merchantTradingName}
            paymentTypeSelected={paymentTypeSelected}
            setPaymentTypeSelected={setPaymentTypeSelected}
          />
          <AttachementContainerBox>
            <AttachmentContainer downloadUrl={attachmentPath ? `${API_URI}/paymentLink/${paymentLinkId}` : undefined} />
          </AttachementContainerBox>
          <ButtonContainer>
            <DeprecatedButton
              leftIcon={WhiteLockLogo}
              $fullWidth
              isLoading={false}
              size="large"
              $variant="primary"
              onClick={goToPaymentPage}
            >
              {t("continueToPayment")}
            </DeprecatedButton>
          </ButtonContainer>
        </Container>
      </Backgound>
      <Footer privacyPolicyUrl={privacyPolicyUrl} />
    </FullPage>
  )
}
